import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
import ReviewsSection from '../../components/ReviewsSection';

import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import iconTick from '../../assets/images/career-service/tick.svg';

import imgTb1 from '../../assets/images/career-service/grads/Logo 1.png';
import imgTb2 from '../../assets/images/career-service/grads/Logo 1.2.png';
import imgTb3 from '../../assets/images/career-service/grads/Logo 1.3.svg';
import imgTb4 from '../../assets/images/career-service/grads/Logo 1.4.png';
import imgTb5 from '../../assets/images/career-service/grads/Logo 1.5.png';
import imgTb6 from '../../assets/images/career-service/grads/Logo 2.svg';
import imgTb7 from '../../assets/images/career-service/grads/Logo 3.png';
import imgTb8 from '../../assets/images/career-service/grads/Logo 4.png';
import imgTb9 from '../../assets/images/career-service/grads/Logo 5.png';
import imgTb10 from '../../assets/images/career-service/grads/Logo 6.png';
import imgTb11 from '../../assets/images/career-service/grads/Logo 7.png';
import imgTb12 from '../../assets/images/career-service/grads/Logo 8.png';
import imgTb13 from '../../assets/images/career-service/grads/Logo 9.svg';
import imgTb14 from '../../assets/images/career-service/grads/Logo 10.png';
import imgTb15 from '../../assets/images/career-service/grads/Logo 11.png';
import imgTb16 from '../../assets/images/career-service/grads/Logo 12.png';

import imgBenefit1 from '../../assets/images/career-service/Work in a Language Academy.svg';
import imgBenefit2 from '../../assets/images/career-service/Teach Corporate Classes.svg';
import imgBenefit3 from '../../assets/images/career-service/Tutor or Teach Privately.svg';
import imgBenefit5 from '../../assets/images/career-service/Find Work Online.svg';
import imgPhotoLeft from '../../assets/images/career-service/Photo 2.jpg';
import imgPhotoRight from '../../assets/images/career-service/Photo 1.jpg';

import '../../assets/styles/pages/career-service-page.scss';

const CareerService = () => {
  return (
    <Layout
      title={{ id: 'careerService.seo.title' }}
      description={{ id: 'careerService.seo.description' }}
    >
      <div className="career-service-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="careerService.sec1.title" />
            </h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="c-title-34 second-section__l">
                  <FormattedMessage id="careerService.sec2.title" />
                </h2>
                <p className="c-text-18 description second-section__l">
                  <FormattedMessage id="careerService.sec2.description" />
                </p>
                <div className="wrapper">
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit1} alt="Work in a Language Academy" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="careerService.sec2.ben1.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="careerService.sec2.ben1.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit2} alt="Teach Business Classes" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="careerService.sec2.ben2.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="careerService.sec2.ben2.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit3} alt="Tutor or Teach Privately" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="careerService.sec2.ben3.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="careerService.sec2.ben3.text" />
                      </p>
                    </div>
                  </div>
                  <div className="benefit-group">
                    <div className="group__img">
                      <img src={imgBenefit5} alt="Find Work Online" />
                    </div>
                    <div className="group__text">
                      <h3 className="c-title-18">
                        <FormattedMessage id="careerService.sec2.ben4.title" />
                      </h3>
                      <p className="c-text-14">
                        <FormattedMessage id="careerService.sec2.ben4.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <div className="right__img">
                    <img src={imgPhotoRight} alt="imgPhotoRight" />
                  </div>
                  <div className="notices">
                    <div className="notice">
                      <h3 className="notice__title">
                        <FormattedMessage id="careerService.sec2.ben5.title" />
                      </h3>
                      <p className="notice__text">
                        <FormattedMessage id="careerService.sec2.ben5.text" />
                      </p>
                    </div>
                    <div className="notice">
                      <h3 className="notice__title">
                        <FormattedMessage id="careerService.sec2.ben6.title" />
                      </h3>
                      <p className="notice__text">
                        <FormattedMessage id="careerService.sec2.ben6.text" />
                      </p>
                    </div>
                    <div className="notice">
                      <h3 className="notice__title">
                        <FormattedMessage id="careerService.sec2.ben7.title" />
                      </h3>
                      <p className="notice__text">
                        <FormattedMessage id="careerService.sec2.ben7.text" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section">
          <div className="wrapper">
            <div className="container block_l">
              <div className="row no-gutters">
                <div className="col-xl-10 ">
                  <div className="block_r">
                    <div className="block_r__img">
                      <img src={imgPhotoLeft} alt="Find Your Dream Job with TEFL Iberia" />
                    </div>
                  </div>
                  <div className="content">
                    <h2 className="c-title-34">
                      <FormattedMessage id="careerService.sec3.title" />
                    </h2>
                    <p className="c-text-18">
                      <FormattedMessage id="careerService.sec3.description" />
                    </p>
                    <div className="c-benefit-list">
                      <h4>
                        {' '}
                        <FormattedMessage id="careerService.sec3.benefitsTitle" />
                      </h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="careerService.sec3.ben1" />
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="careerService.sec3.ben2" />
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="careerService.sec3.ben3" />
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="careerService.sec3.ben4" />
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          <FormattedMessage id="careerService.sec3.ben4" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container table-container c-section-py">
            <h2 className="table-container__title">
              <FormattedMessage id="careerService.sec4.title" />
            </h2>
            <table className="table" border="1" bordercolor="#F0F0F2">
              <tr>
                <td className="icon">
                  <img className="icon__img" src={imgTb1} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb2} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb3} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb4} alt="imgTb1" />
                </td>
              </tr>
              <tr>
                <td className="icon">
                  <img className="icon__img" src={imgTb5} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb6} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb7} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb8} alt="imgTb1" />
                </td>
              </tr>
              <tr>
                <td className="icon">
                  <img className="icon__img" src={imgTb9} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb10} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb11} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb12} alt="imgTb1" />
                </td>
              </tr>
              <tr>
                <td className="icon">
                  <img className="icon__img" src={imgTb13} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb14} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb15} alt="imgTb1" />
                </td>
                <td className="icon">
                  <img className="icon__img" src={imgTb16} alt="imgTb1" />
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section className="nineth-section">
          <ReviewsSection
            hideAllReviews
            description={{ id: 'graduates.teflSpanishCourses.description' }}
            title={{ id: 'graduates.teflSpanishCourses.title' }}
          />
        </section>

        <section className="seventh-section">
          <div className="container">
            <h2 className="c-title-38">
              {/* Ready to Take the First Steps Towards{' '}
              <span className="colored no-link">Your New Career?</span> */}
              <FormattedMessage
                id="careerService.sec7.title"
                values={{
                  accent: (chunk) => <span className="colored no-link">{chunk}</span>,
                }}
              />
            </h2>
            <p className="c-text-18">
              <FormattedMessage id="careerService.sec7.description" />
            </p>
            {/* <Link to="/apply/" className="c-btn c-btn--red">
              Apply now
              <IconArrowRight />
            </Link> */}
            <FormattedMessage id="url.apply.index">
              {(locale) => (
                <Link to={locale[0]} className="c-btn c-btn--red">
                  <FormattedMessage id="menu.applyNow" />
                  <IconArrowRight />
                </Link>
              )}
            </FormattedMessage>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default CareerService;
